import React from 'react';
import { Navigate, redirect, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import ToastContainer from './ToastContainer';
import { ToastProvider } from '../contexts/ToastContext';
import BaselineStrategyProvider from '../contexts/BaselineStrategyContext';
import SidebarProvider from '../contexts/SideBarContext';
import AssetProvider from '../contexts/AssetContext';
import UpdateHierarchy from '../pages/UpdateHierarchy';
import Reporting from '../pages/Reporting';
import { DetailAnalysis } from '../pages/DetailAnalysis';
import { SummaryReport } from './SummaryReport';
import Strategies from '../pages/Strategies';
import { AssetEdit } from '../pages/Asset/AssetEdit';
import SubscriptionList from '../pages/Subscription/SubscriptionList';
import SubscriptionEdit from '../pages/Subscription/SubscriptionEdit';
import SubscriptionView from '../pages/Subscription/SubscriptionView';
import ClientList from '../pages/Client/ClientList';
import ClientEdit from '../pages/Client/ClientEdit';
import ClientView from '../pages/Client/ClientView';
import UserEdit from '../pages/User/UserEdit';
import UserView from '../pages/User/UserView';
import { Strategy } from '../pages/Strategy';
import AssetSearch from '../pages/AssetSearch';
import JobList from '../pages/Job/JobList';
import JobEdit from '../pages/Job/JobEdit';
import GroupView from '../pages/Group/GroupView';
import GroupEdit from '../pages/Group/GroupEdit';
import GroupList from '../pages/Group/GroupList';
import AssetView from '../pages/Asset/AssetView';
import ReplacementCostList from '../pages/ReplacementCost/AssetReplacementCostList';
import { AssetReplacementCostEdit } from '../pages/ReplacementCost/AssetReplacementCostEdit';
import AssetReplacementCostView from '../pages/ReplacementCost/AssetReplacementCostView';
import { ComponentEdit } from '../pages/Component/ComponentEdit';
import ComponentView from '../pages/Component/ComponentView';
import AssetSearchProvider from '../contexts/AssetSearchContext';
import ComponentSearchProvider from '../contexts/ComponentSearchContext';
import ComponentSearch from '../pages/ComponentSearch';
import { AssetImport } from '../pages/AssetImport';
import { HierarchyNavigation } from '../pages/HierarchyNavigation';
import { InsuranceAssumptionsView } from '../pages/Assumptions/InsuranceAssumptionsView';
import InsuranceAssumptionsEdit from '../pages/Assumptions/InsuranceAssumptionsEdit';
import InsuranceAssumptionsSearch from '../pages/Assumptions/InsuranceAssumptionsSearch';
import { AssetAssumptionsView } from '../pages/Assumptions/AssetAssumptionsView';
import AssetAssumptionsEdit from '../pages/Assumptions/AssetAssumptionsEdit';
import AssetAssumptionsSearch from '../pages/Assumptions/AssetAssumptionsSearch';
import ComponentAssumptionsSearch from '../pages/Assumptions/ComponentAssumptionsSearch';
import { ComponentAssumptionsView } from '../pages/Assumptions/ComponentAssumptionsView';
import ComponentAssumptionsEdit from '../pages/Assumptions/ComponentAssumptionsEdit';
import { ValuationProfileList } from '../pages/ValuationProfileList';
import { ValuationProfileView } from '../pages/ValuationProfileView';
import { ValuationProfileEdit } from '../pages/ValuationProfileEdit';
import { RulesList } from '../pages/Rules/RulesList';
import ValuationClassList from '../pages/ValuationClass/ValuationClassList';
import { RuleEdit } from '../pages/Rules/RuleEdit';
import { ChangeClient } from '../models/ChangeClient';
import { LeaseView } from '../pages/Lease/LeaseView';
import { LeaseList } from '../pages/Lease/LeaseList';
import { LeaseEditNav } from '../pages/Lease/LeaseEditNav';
import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';
import UserList from '../pages/User/UserList';
import { ComponentReplacementCostEdit } from '../pages/ReplacementCost/ComponentReplacementCostEdit';
import { ImageImport } from '../pages/ImageImport';
import ComponentReplacementCostView from '../pages/ReplacementCost/ComponentReplacementCostView';
import { ValuationClassEdit } from '../pages/ValuationClass/ValuationClassEdit';
import NotificationProvider from '../contexts/NotificationContext';
import { LeaseEditor } from '../pages/Lease/LeaseEditor';
import StrategyProvider from '../contexts/StrategyContext';
import { DocumentImport } from '../pages/DocumentImport';
import FinancialClassList from '../pages/FinancialClass/FinancialClassList';
import LeaseSearchProvider from '../contexts/LeaseSearchContext';
import { LeaseImport } from '../pages/LeaseImport';
import { NotificationList } from '../pages/NotificationList';
import { RestrictedPage } from './Restricted';
import AssumptionsSearchProvider from '../contexts/AssumptionsContext';
import { AssetQuickEdit } from './Asset/QuickEdit/AssetQuickEdit';
import ImportJobLogView from '../pages/ImportLog/ImportJobLogView';
import CopyNodeProvider from '../contexts/CopyNodeContext';
import ImportFrameworkLogView from '../pages/ImportLog/ImportFrameworkLogView';
import { General } from '../pages/Asset/General';
import Location from '../pages/Asset/Location';
import Valuation from '../pages/Asset/Valuation';
import { ComponentList } from '../pages/Component/ComponentList';
import Images from '../pages/Asset/Images';
import Notes from '../pages/Asset/Notes';
import { InsuranceView } from '../pages/Asset/Insurance';
import ValuationComparison from '../pages/Asset/ValuationComparison';
import MarketApproachDetail from '../pages/Asset/MarketApproachDetail';
import IncomeApproachDetail from '../pages/Asset/IncomeApproachDetail';
import ValuationDetail from '../pages/Asset/ValuationDetail';
import { AssetMainForm } from './Asset/AssetMainForm';
import ImageViewer from './Asset/ImageViewer';
import { Accounting } from './Asset/Accounting';
import { ValuationEditor } from './Asset/ValuationEditor';
import InsuranceForm from './Asset/InsuranceForm';
import { LocationForm } from './Asset/LocationForm';
import { TenantEditor } from '../pages/Lease/TenantEditor';
import { InsuranceEditor } from '../pages/Lease/InsuranceEditor';
import { GuaranteeEditor } from '../pages/Lease/GuaranteeEditor';
import { ReviewEditor } from '../pages/Lease/ReviewEditor';
import { LeaseDetail } from '../pages/Lease/Detail';
import { TenantList } from '../pages/Lease/Tenant';
import { Guarantee } from '../pages/Lease/Guarantee';
import { Insurance } from '../pages/Lease/Insurance';
import { Review } from '../pages/Lease/Review';
import { ContentList } from '../pages/Content/ContentList';
import ContentView from '../pages/Content/ContentView';
import { FrameworkImport } from '../pages/FrameworkImport';
import JobView from '../pages/Job/JobView';
import { MaintenancePlanSearch } from '../pages/MaintenancePlan/MaintenancePlanSearch';
import MaintenancePlanSearchProvider from '../contexts/MaintenancePlanSearchContext';
import MaintenancePlanView from '../pages/MaintenancePlan/MaintenancePlanView';
import { MaintenancePlanEdit } from '../pages/MaintenancePlan/MaintenancePlanEdit';
import JobContextProvider from '../contexts/JobContext';

export const AuthenticatedContent: React.FC = () => {
  const { roles } = useAuth();

  return (
    <ToastProvider>
      <ToastContainer>
        <NotificationProvider>
          <SidebarProvider>
            <AssetProvider>
              <AssumptionsSearchProvider>
                <JobContextProvider>
                  <AssetSearchProvider>
                    <MaintenancePlanSearchProvider>
                      <LeaseSearchProvider>
                        <ComponentSearchProvider>
                          <BaselineStrategyProvider>
                            <StrategyProvider>
                              <CopyNodeProvider>
                                <Layout>
                                  <Routes>
                                    {roles[0] === Roles.Administrator && <Route path="/" element={<ClientList />} />}
                                    {roles[0] === Roles.GAdministrator && <Route path="/" element={<ClientList />} />}
                                    {(roles.includes(Roles.CAdministrator) ||
                                      roles.includes(Roles.Valuer) ||
                                      roles.includes(Roles.Viewer) ||
                                      roles.includes(Roles.Editor)) && <Route path="/" element={<JobList />} />}
                                    <Route path="/reports" element={<Reporting />} />
                                    <Route path="/logout-callback" element={<Navigate to="/" replace />} />
                                    <Route
                                      path="/Reports/detailanalysis/:strategyid/:comparisonstrategyid"
                                      element={<DetailAnalysis />}
                                    />
                                    {/* <Route path="/Reports/summaryreport/:name/:comparisonstrategy" element={SummaryReport} /> */}
                                    <Route path="/updatehierarchy" element={<UpdateHierarchy />} />
                                    <Route path="/strategy" element={<Strategies />} />
                                    <Route path="/strategy/:id" element={<Strategy />} />
                                    <Route path="/jobs" element={<JobList />} />
                                    <Route path="/jobs/:id/edit" element={<JobEdit />} />
                                    <Route path="/jobs/:id/import" element={<AssetImport />} />
                                    <Route path="/jobs/:id/importimages" element={<ImageImport />} />
                                    <Route path="/jobs/:id/importdocuments" element={<DocumentImport />} />
                                    <Route path="/jobs/:id" element={<JobView />} />
                                    <Route
                                      path="/subscriptions"
                                      element={roles[0] === Roles.Administrator ? <SubscriptionList /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/subscriptions/:id/edit"
                                      element={roles[0] === Roles.Administrator ? <SubscriptionEdit /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/subscriptions/:id"
                                      element={roles[0] === Roles.Administrator ? <SubscriptionView /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/subscriptions/:subscriptionId/groups/:id/edit"
                                      element={roles[0] === Roles.Administrator ? <GroupEdit /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/subscriptions/:subscriptionId/clients/:id/edit"
                                      element={roles[0] === Roles.Administrator ? <ClientEdit /> : <RestrictedPage />}
                                    />
                                    <Route path="/valuationclasses" element={<ValuationClassList />} />
                                    <Route path="/valuationclasses/:id/edit" element={<ValuationClassEdit />} />
                                    <Route path="/financialclasses" element={<FinancialClassList />} />
                                    <Route path="/clients" element={<ClientList />} />
                                    <Route path="/clients/:id/edit" element={<ClientEdit />} />
                                    <Route path="/clients/:id" element={<ClientView />} />
                                    <Route path="/currentclient/:id" element={<ClientView />} />
                                    <Route
                                      path="/users/:id/edit"
                                      element={roles[0] === Roles.Administrator ? <UserEdit /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/users/:id"
                                      element={roles[0] === Roles.Administrator ? <UserView /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/users"
                                      element={roles[0] === Roles.Administrator ? <UserList /> : <RestrictedPage />}
                                    />
                                    <Route
                                      path="/groups"
                                      element={roles[0] === Roles.Administrator ? <GroupList /> : <RestrictedPage />}
                                    />
                                    <Route path="/groups/:id/edit" element={<GroupEdit />} />
                                    <Route path="/groups/:id" element={<GroupView />} />
                                    <Route path="/groups/:groupId/clients/:id/edit" element={<ClientEdit />} />
                                    <Route path="/assets/search" element={<AssetSearch />} />
                                    <Route path="/assets/:id" element={<AssetView />}>
                                      <Route index element={<General />} />
                                      <Route path="valuation" element={<Valuation />}>
                                        <Route index element={<ValuationComparison />} />
                                        <Route path="market" element={<MarketApproachDetail />} />
                                        <Route path="income" element={<IncomeApproachDetail />} />
                                        <Route path="comparison" element={<ValuationComparison />} />
                                        <Route path="detail" element={<ValuationDetail />} />
                                      </Route>
                                      <Route path="location" element={<Location />} />
                                      <Route path="components" element={<ComponentList />} />
                                      <Route path="images" element={<Images />} />
                                      <Route path="notes" element={<Notes />} />
                                      <Route path="contents" element={<ContentList />} />
                                      <Route path="insurance" element={<InsuranceView />} />
                                      <Route path="replacementcosts" element={<ReplacementCostList />} />
                                    </Route>
                                    <Route path="/assets/:id/edit" element={<AssetEdit />}>
                                      <Route index element={<AssetMainForm />} />
                                      <Route path="Images" element={<ImageViewer />} />
                                      <Route path="Accounting" element={<Accounting />} />
                                      <Route path="Valuation" element={<ValuationEditor />} />
                                      <Route path="Insurance" element={<InsuranceForm />} />
                                      <Route path="Location" element={<LocationForm />} />
                                    </Route>
                                    <Route path="/assets/:id/quickedit" element={<AssetQuickEdit />} />
                                    <Route path="/leases/search" element={<LeaseList />} />
                                    <Route path="/leases/:id/edit/:assetId?" element={<LeaseEditNav />}>
                                      <Route index element={<LeaseEditor />} />
                                      <Route path="Tenant" element={<TenantEditor />} />
                                      <Route path="Guarantee" element={<GuaranteeEditor />} />
                                      <Route path="Insurance" element={<InsuranceEditor />} />
                                      <Route path="Review" element={<ReviewEditor />} />
                                    </Route>
                                    <Route path="/leases/:id" element={<LeaseView />}>
                                      <Route index element={<LeaseDetail />} />
                                      <Route path="tenant" element={<TenantList />} />
                                      <Route path="guarantee" element={<Guarantee />} />
                                      <Route path="insurance" element={<Insurance />} />
                                      <Route path="review" element={<Review />} />
                                    </Route>
                                    <Route path="/assetreplacementcosts" element={<ReplacementCostList />} />
                                    <Route
                                      path="/assetreplacementcosts/:id/edit/:assetId?"
                                      element={<AssetReplacementCostEdit />}
                                    />
                                    <Route path="/assetreplacementcosts/:id" element={<AssetReplacementCostView />} />
                                    <Route path="/contents/:id" element={<ContentView />} />
                                    <Route path="/components/search" element={<ComponentSearch />} />
                                    <Route path="/components/:id/edit/:assetId?" element={<ComponentEdit />} />
                                    <Route path="/components/:id" element={<ComponentView />} />
                                    <Route path="/notifications" element={<NotificationList />} />
                                    <Route
                                      path="/componentreplacementcosts/:id/edit/:componentId?"
                                      element={<ComponentReplacementCostEdit />}
                                    />
                                    <Route
                                      path="/componentreplacementcosts/:id"
                                      element={<ComponentReplacementCostView />}
                                    />
                                    <Route path="/framework" element={<FrameworkImport />} />
                                    <Route path="/importjoblog/:id" element={<ImportJobLogView />} />
                                    <Route path="/importframeworklog/:id" element={<ImportFrameworkLogView />} />
                                    <Route path="/assetassumptions/search" element={<AssetAssumptionsSearch />} />
                                    <Route path="/assetassumptions/:id/edit" element={<AssetAssumptionsEdit />} />
                                    <Route path="/assetassumptions/:id" element={<AssetAssumptionsView />} />
                                    <Route path="/insuranceassumptions/search" element={<InsuranceAssumptionsSearch />} />
                                    <Route path="/insuranceassumptions/:id/edit" element={<InsuranceAssumptionsEdit />} />
                                    <Route path="/insuranceassumptions/:id" element={<InsuranceAssumptionsView />} />
                                    <Route path="/componentassumptions/search" element={<ComponentAssumptionsSearch />} />
                                    <Route path="/componentassumptions/:id/edit" element={<ComponentAssumptionsEdit />} />
                                    <Route path="/componentassumptions/:id" element={<ComponentAssumptionsView />} />
                                    <Route path="/valuationprofiles/:id/edit" element={<ValuationProfileEdit />} />
                                    <Route path="/valuationprofiles/:id" element={<ValuationProfileView />} />
                                    <Route path="/valuationprofiles/" element={<ValuationProfileList />} />
                                    <Route path="/hierarchy/browse" element={<HierarchyNavigation />} />
                                    <Route path="/rules/:id/edit" element={<RuleEdit />} />
                                    <Route path="/rules" element={<RulesList />} />
                                    <Route path="/changeclient" element={<ChangeClient />} />
                                    <Route path="/leaseimport" element={<LeaseImport />} />
                                    <Route path="/avp" element={<HierarchyNavigation />} />
                                    <Route path="/maintenanceplans/search" element={<MaintenancePlanSearch />} />
                                    <Route path="/maintenanceplans/:id" element={<MaintenancePlanView />} />
                                    <Route path="/maintenanceplans/:id/edit" element={<MaintenancePlanEdit />} />
                                  </Routes>
                                </Layout>
                              </CopyNodeProvider>
                            </StrategyProvider>
                          </BaselineStrategyProvider>
                        </ComponentSearchProvider>
                      </LeaseSearchProvider>
                    </MaintenancePlanSearchProvider>
                  </AssetSearchProvider>
                </JobContextProvider>
              </AssumptionsSearchProvider>
            </AssetProvider>
          </SidebarProvider>
        </NotificationProvider>
      </ToastContainer>
    </ToastProvider>
  );
};
