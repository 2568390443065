import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Component } from '../../../models/Component';
import { currencyFormat } from '../../../Format';
import { useApi } from '../../../contexts/ApiContext';
import { ToastType, useToast } from '../../../contexts/ToastContext';
import { ReactComponent as Loader } from '../../../icons/Loader.svg';

type Props = {
  component: Component;
  isOpen: boolean;
  closeModal(): void;
};

const CalculateScoreModal: React.FC<Props> = ({ component, isOpen, closeModal }) => {
  const api = useApi();
  const { addToast } = useToast();
  const [isCalculating, setCalculating] = useState<boolean>(false);
  const [additions, setAdditions] = useState<number>(0);

  const { register, reset, getValues, setValue } = useForm<Component>({
    defaultValues: { ...component },
    mode: 'onSubmit',
  });

  const calculateScore = (): void => {
    setCalculating(true);
    api
      .post<number>(`/api/Component/CalculateConsumptionScore`, {
        proposedCurrentValueShort: getValues('currentValueShort') + additions,
        componentId: component.id,
      })
      .then(({ data }) => {
        setValue('consumptionScore', data);
      })
      .catch((error) => {
        addToast('Could not get score', ToastType.Error);
      })
      .finally(() => {
        setCalculating(false);
      });
  };

  useEffect(() => {
    reset({ ...component });
  }, [component]);

  return (
    <Dialog open={isOpen} onClose={closeModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <form className="space-y-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 ring-gray-300">Calculate Score </h3>
              </div>

              <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="text-right py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                        Current Value Short
                      </th>
                      <th scope="col" className="text-right py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                        Additions
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 lg:table-cell" />
                      <th scope="col" className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900 lg:table-cell">
                        Proposed Score
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">
                          <input
                            disabled
                            type="text"
                            defaultValue={currencyFormat.format(component.currentValueShort)}
                            className="text-right block w-full rounded-md border-0 disabled:opacity-50 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </td>
                      <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">
                          <input
                            type="number"
                            defaultValue={additions}
                            onChange={(e): void => {
                              setAdditions(parseInt(e.target.value));
                            }}
                            className="text-right block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </td>
                      <td className="px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                        <button onClick={(): void => calculateScore()} type="button" className="btn btn-secondary">
                          {isCalculating ? (
                            <Loader className="animate-spin -ml-0.5 h-5 w-5 text-gray-400" />
                          ) : (
                            <CalculatorIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-gray-400" />
                          )}
                        </button>
                      </td>
                      <td className="px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                        <input
                          type="number"
                          {...register('consumptionScore', { valueAsNumber: true })}
                          disabled
                          className="text-right disabled:opacity-50 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default CalculateScoreModal;
