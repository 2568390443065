import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { XMarkIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { Asset } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { useToast } from '../../contexts/ToastContext';
import { Note } from '../../models/Note';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { NoteModal } from '../../components/Asset/NoteModal';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

interface Comment {
  noteDetail: string;
  noteType: string;
}

const Notes: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const api = useApi();
  const noteTypes = ['General', 'Valuation Policy', 'Information Request', 'Valuer', 'Location'];
  const [noteTypesAvailable, setNoteTypesAvailable] = useState<string[]>(noteTypes);
  const { addToast } = useToast();
  const { data: { notes } = {} as Asset } = useAssetById(parseInt(id));
  const [notesArray, setNotesArray] = useState<Note[]>([]);
  const [confirmationBox, setConfirmationBox] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [noteId, setNoteId] = useState<number>(0);
  const [noteTag, setNoteTag] = useState<string>('');
  const [noteDetail, setNoteDetail] = useState<string>('');

  useEffect(() => {
    if (notes !== undefined && notes.length > 0) {
      setNotesArray(notes);
      const noteTypesUsed = notes.map((note) => note.tag);
      setNoteTypesAvailable(noteTypesAvailable.filter((noteType) => !noteTypesUsed.includes(noteType)));
    }
  }, [notes]);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<Comment>({
    reValidateMode: 'onBlur',
  });

  const onSubmit = (Comment: Comment) => {
    const noteToUpdate: Note = {
      id: 0,
      tag: noteTag,
      detail: Comment.noteDetail,
    };
    setIsSaving(true);
    api
      .post('/api/Asset/UpdateNote', { assetId: id, note: noteToUpdate })
      .then(({ data }) => {
        setIsSaving(false);
        setNotesArray(data);
        addToast('Updated note');
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const openConfirmationBox = (nid: number): void => {
    setNoteId(nid);
    setConfirmationBox(true);
  };

  const openEditModal = (nid: number): void => {
    setNoteId(nid);
    setEditModal(true);
  };

  const removeNote = (): void => {
    api.post('/api/Asset/DeleteNote', { id: noteId }).then(
      (result) => {
        setNotesArray(notesArray.filter((n) => n.id !== noteId));
        setNoteTypesAvailable([...noteTypesAvailable, notesArray.find((note) => note.id == noteId)?.tag ?? '']);
        addToast('Deleted Note');
      },
      (error) => {
        addToast('Unable to delete note');
      }
    );
  };

  const updateNote = (): void => {
    const noteToUpdate: Note = {
      id: noteId,
      tag: noteTag,
      detail: noteDetail,
    };
    api
      .post('/api/Asset/UpdateNote', { assetId: id, note: noteToUpdate })
      .then(({ data }) => {
        setNotesArray(data);
        addToast('Updated note');
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (notesArray.length > 0) {
      const noteTypesUsed = notesArray.map((note) => note.tag);
      setNoteTypesAvailable(noteTypes.filter((noteType) => !noteTypesUsed.includes(noteType)));
    }
  }, [notesArray]);

  useEffect(() => {
    if (noteTypesAvailable.length > 0) {
      setNoteTag(noteTypesAvailable[0]);
    }
  }, [noteTypesAvailable]);

  return (
    <>
      <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-6 sm:px-6">
            <ul className="space-y-8">
              {notesArray.map((note) => (
                <li key={note.id}>
                  <div className="flex space-x-3">
                    <div>
                      <div className="text-sm font-medium text-gray-900">{note.tag}</div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>{note.detail}</p>
                      </div>
                      <div className="mt-2 flex space-x-8 text-sm">
                        <button
                          onClick={(): void => {
                            openEditModal(note.id);
                            setNoteTag(note.tag ? note.tag : '');
                            setNoteDetail(note.detail);
                          }}
                          className="inline-flex items-center gap-x-1.5 rounded-full bg-white py-1.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          <PencilSquareIcon className="-ml-0.5 h-5 w-5 text-indigo-600" aria-hidden="true" />
                          Edit
                        </button>
                        <button
                          onClick={(): void => openConfirmationBox(note.id)}
                          className="inline-flex items-center gap-x-1.5 rounded-full bg-white py-1.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-red-700"
                        >
                          <TrashIcon className="h-5 w-5 text-indigo-600 hover:text-red-700 font-medium rounded-md focus:outline-none" />
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {noteTypesAvailable.length > 0 && (
          <div className="relative bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="min-w-0 flex-1">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <textarea
                      id="comment"
                      {...register('noteDetail')}
                      rows={3}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Add a note"
                      defaultValue=""
                    />
                  </div>
                  <div className="mt-3 flex items-center justify-between">
                    <select
                      {...register('noteType')}
                      onChange={(e): void => {
                        setNoteTag(e.target.value);
                      }}
                      className="block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {noteTypesAvailable.map((note) => (
                        <option value={note}>{note}</option>
                      ))}
                    </select>
                    <button
                      disabled={isSaving}
                      type="submit"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {isSaving ? (
                        <>
                          <span>Saving</span>
                          <Loader className="animate-spin w-5 h-5 mx-2" />
                        </>
                      ) : (
                        <span>Comment</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <DeleteConfirmation isOpen={confirmationBox} setOpen={setConfirmationBox} itemType="Note" deleteAction={removeNote} />
      <NoteModal
        isOpen={editModal}
        setOpen={setEditModal}
        setNoteDetail={setNoteDetail}
        noteTag={noteTag}
        setNoteTag={setNoteTag}
        notesAvailable={noteTypesAvailable}
        noteDetail={noteDetail}
        updateNote={updateNote}
      />
    </>
  );
};

export default Notes;
